import { debounce } from 'lodash'

export const leadDebounce = <T>(callback: (payload: T) => void) =>
  debounce(callback, 1000, {
    trailing: false,
    leading: true,
  })

export const trailDebounce = <T>(callback: (payload: T) => void) =>
  debounce(callback, 600, {
    trailing: true,
    leading: false,
  })
